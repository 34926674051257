import { Flex, Image } from '@mantine/core';
import * as React from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code';

interface Props {
  appStoreUrl: string;
  googlePlayStoreUrl: string;
}

export const DownloadApp: React.FC<Props> = ({ appStoreUrl, googlePlayStoreUrl }) => {
  return (
    <Flex direction={{ base: 'column', sm: 'row' }} align={{ base: 'start', sm: 'center' }} justify="start" py={12}>
      <Flex direction="column" mr={{ xs: 0, md: 100 }} mb={40}>
        <Link target="_blank" to={googlePlayStoreUrl}>
          <Image
            fit="contain"
            width={200}
            mr={{ xs: 0, sm: 12 }}
            sx={{ flexShrink: 0, cursor: 'pointer', marginBottom: 40 }}
            src="/img/play-store.png"
            alt="google-play"
          />
        </Link>
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={googlePlayStoreUrl}
          viewBox={`0 0 256 256`}
        />
      </Flex>
      <Flex direction="column" mb={40}>
        <Link target="_blank" to={appStoreUrl}>
          <Image
            fit="contain"
            width={200}
            sx={{ flexShrink: 0, cursor: 'pointer', marginBottom: 40 }}
            src="/img/app-store.png"
            alt="app-store"
          />
        </Link>
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={appStoreUrl}
          viewBox={`0 0 256 256`}
        />
      </Flex>
    </Flex>
  );
};
