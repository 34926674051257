import {
  AccessPolicy,
  BundleEntry,
  Consent,
  Flag,
  Goal,
  Group,
  OperationOutcome,
  Organization,
  Patient,
  ProjectMembership,
  Reference,
} from '@medplum/fhirtypes';
import { UseMutationOptions, useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';
import { getConfig } from '../../config';
import { useMedplum } from '@medplum/react';


export interface INewOrganizationForm {
  //Step 1
  companyName: string;
  city: string;
  address: string;
  zipCode: string;
  phone: string;
  isBillingAddressDifferent: boolean;
  billingCity: string;
  billingAddress: string;
  billingZipCode: string;
  //Step 2
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonEmail: string;
  //Step 3
  plan: string;
  amountOfLicenses: string;
  discount: string;
  taxCountryCode: string;
  taxId: string;
  freeTrialDays: number;
}

export const useCreateNewOrganization = (
  options?: Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn' | 'mutationKey'>
) => {
  const medplum = useMedplum();
  const {adminPatientAccessPolicy, projectId} = getConfig()
  const organizationId = uuid();
  const groupId = uuid();

  return useMutation(async (formData: INewOrganizationForm) => {
    // First create the ProjectMembership and Patient with invite method and pass the Admin Patient AccessPolicy
    const membership: ProjectMembership | OperationOutcome = await medplum.invite(projectId as string, {
      resourceType: 'Patient',
      firstName: formData.contactPersonFirstName,
      lastName: formData.contactPersonLastName,
      email: formData.contactPersonEmail,
      sendEmail: false,
      accessPolicy: {
        reference: `AccessPolicy/${adminPatientAccessPolicy}`,
      },
    });

    if (membership?.resourceType === 'OperationOutcome') return membership;

    const patientReference: Reference = membership?.profile as Reference;

    if (!patientReference?.reference) throw Error('Error: Patient is undefined');

    const patient = await medplum.readResource('Patient', patientReference?.reference?.split('/')?.[1]);

    const organizationPlanJson = {
      paymentStatus: 'Unpaid',
    };

    const organizationBody: Organization = {
      resourceType: 'Organization',
      name: formData.companyName,
      active: true,
      telecom: [
        {
          system: 'phone',
          value: formData.phone,
        },
      ],
      address: [
        {
          city: formData.city,
          line: [formData.address],
          postalCode: formData.zipCode,
          country: 'Germany',
          use: 'work',
        },
        {
          city: formData.isBillingAddressDifferent ? formData.billingCity : formData.city,
          line: formData.isBillingAddressDifferent ? [formData.billingAddress] : [formData.address],
          postalCode: formData.isBillingAddressDifferent ? formData.billingZipCode : formData.zipCode,
          country: 'Germany',
          use: 'billing',
        },
      ],
      contact: [
        {
          purpose: {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/contactentity-type',
                code: 'ADMIN',
              },
            ],
          },
          name: {
            family: formData.contactPersonLastName,
            given: [formData.contactPersonFirstName],
          },
          telecom: [
            {
              system: 'email',
              value: formData.contactPersonEmail,
            },
          ],
        },
      ],
      extension: [
        {
          url: 'http://subscription-details',
          valueString: JSON.stringify(organizationPlanJson),
        },
      ],
    };

    const groupBody: Group = {
      resourceType: 'Group',
      name: 'Default',
      type: 'person',
      actual: true,
      identifier: [
        {
          value: `urn:uuid:${organizationId}`,
        },
      ],
      code: {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/group-type',
            code: 'Ungrouped',
            display: 'Ungrouped',
          },
        ],
      },
      managingEntity: {
        reference: `urn:uuid:${organizationId}`,
        display: `${formData.companyName}`,
      },
      member: [
        {
          entity: {
            reference: `Patient/${patient?.id}`,
            display: `${formData.contactPersonFirstName} ${formData.contactPersonLastName}`,
          },
        },
      ],
    };

    // We will use this Flag resource type to identify this patient is Company Admin
    const flagBody: Flag = {
      resourceType: 'Flag',
      status: 'active',
      author: {
        reference: `urn:uuid:${organizationId}`,
        display: `${formData.companyName}`,
      },
      subject: {
        reference: `Patient/${patient?.id}`,
        display: `${formData.contactPersonFirstName} ${formData.contactPersonLastName}`,
      },
      identifier: [
        {
          id: 'company-admin',
          value: 'company-admin',
        },
      ],
      code: {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/flag-category',
            code: 'company-admin',
            display: 'Company Admin',
          },
        ],
      },
    };

    // We will use Consent to observe if invitation email is sent or not.
    const consentBody: Consent = {
      resourceType: 'Consent',
      status: 'active',
      organization: [
        {
          reference: `urn:uuid:${organizationId}`,
          display: formData.companyName,
        },
      ],
      patient: {
        reference: `Patient/${patient?.id}`,
        display: `${formData.contactPersonFirstName} ${formData.contactPersonLastName}`,
      },
      policyRule: {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/consentpolicycodes',
            code: 'cric',
            display: 'Common Rule Informed Consent',
          },
        ],
      },
      category: [
        {
          coding: [
            {
              system: 'http://loinc.org',
              code: '59284-0',
              display: 'Patient Consent',
            },
          ],
        },
      ],
      scope: {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/consentscope',
            code: 'patient-privacy',
            display: 'Privacy Consent',
          },
        ],
      },
      identifier: [
        {
          system: 'patient-email',
          value: patient?.telecom?.find((t) => t?.system === 'email')?.value,
        },
      ],
    };

    const defaultGoals = [
      {
        code: 'Steps (steps)',
        measureCode: '55423-8',
        unit: 'steps',
        value: 8000,
      },
      {
        code: 'Activity (calories)',
        measureCode: '41981-2',
        unit: 'calories',
        value: 200,
      },
      {
        code: 'Mindful (sessions)',
        measureCode: '56293-5',
        unit: 'sessions',
        value: 3,
      },
    ];

    const goals: Goal[] = defaultGoals.map((goal) => {
      return {
        resourceType: 'Goal',
        lifecycleStatus: 'active',
        identifier: [{ value: `urn:uuid:${organizationId}` }],
        category: [
          {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/goal-category',
                code: 'behavioral',
                display: 'Behavioral',
              },
            ],
          },
        ],
        description: {
          coding: [
            {
              code: goal.code,
              display: goal.code,
            },
          ],
        },
        subject: {
          reference: `urn:uuid:${groupId}`,
          display: groupBody.name,
        },
        startDate: new Date().toISOString().split('T')[0],
        target: [
          {
            measure: {
              coding: [
                {
                  code: goal.measureCode,
                  display: goal.measureCode,
                },
              ],
            },
            detailQuantity: {
              value: goal.value,
              unit: goal.unit,
              comparator: '>=',
            },
          },
        ],
      };
    });

    const goalBundleEntries: BundleEntry<Goal>[] = goals.map((x: Goal) => {
      return {
        fullUrl: `urn:uuid:${uuid()}`,
        request: {
          method: 'POST',
          url: '/Goal',
        },
        resource: {
          ...x,
          id: undefined,
        },
      };
    });

    // Bundle entries should be in correct order!
    const bundleEntries: BundleEntry<
      Organization | Group | Goal | Patient | AccessPolicy | ProjectMembership | Flag | Consent
    >[] = [
      //ORGANIZATION
      {
        fullUrl: `urn:uuid:${organizationId}`,
        request: {
          method: 'POST',
          url: '/Organization',
        },
        resource: organizationBody,
      },
      //GROUP
      {
        fullUrl: `urn:uuid:${groupId}`,
        request: {
          method: 'POST',
          url: '/Group',
        },
        resource: groupBody,
      },
      //GOALS
      ...goalBundleEntries,
      // PATIENT - UPDATE
      {
        request: {
          method: 'PUT',
          url: `/Patient/${patient?.id}`,
        },
        resource: {
          ...patient,
          active: true,
          managingOrganization: {
            reference: `urn:uuid:${organizationId}`,
            display: formData.companyName,
          },
          telecom: [
            ...(patient?.telecom ?? []),
            {
              system: 'phone',
              value: formData.phone,
            },
          ]
        },
      },
      // PROJECT MEMBERSHIP - UPDATE
      {
        fullUrl: `urn:uuid:${membership.id}`,
        request: {
          method: 'PUT',
          url: `/ProjectMembership/${membership.id}`,
        },
        resource: {
          ...membership,
          access: [
            {
              policy: {
                reference: `AccessPolicy/${adminPatientAccessPolicy}`,
              },
              parameter: [
                {
                  name: 'provider_organization',
                  valueReference: {
                    reference: `urn:uuid:${organizationId}`,
                  },
                },
              ],
            },
          ],
        },
      },
      // FLAG
      {
        request: {
          method: 'POST',
          url: '/Flag',
        },
        resource: {
          ...flagBody,
        },
      },
      // CONSENT
      {
        request: {
          method: 'POST',
          url: '/Consent',
        },
        resource: {
          ...consentBody,
        },
      },
    ];

    return medplum.executeBatch({
      resourceType: 'Bundle',
      type: 'transaction',
      entry: bundleEntries,
    });
  }, options);
};
