import { Button, Center, Group, PasswordInput, Stack, Title } from '@mantine/core';
import { badRequest, normalizeOperationOutcome } from '@medplum/core';
import { Narrative, OperationOutcome } from '@medplum/fhirtypes';
import { Document, Form, getErrorsForInput, MedplumLink, useMedplum } from '@medplum/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export function SetPasswordPage(): JSX.Element {
  const { t } = useTranslation();
  const { id, secret } = useParams() as { id: string; secret: string };
  const medplum = useMedplum();
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const [success, setSuccess] = useState(false);

  return (
    <Document width={450} className="reset-password">
      <Form
        style={{ maxWidth: 400 }}
        onSubmit={(formData: Record<string, string>) => {
          if (formData.password !== formData.confirmPassword) {
            setOutcome(badRequest(t('auth.passwords-do-not-match'), 'confirmPassword'));
            return;
          }
          setOutcome(undefined);
          const body = {
            id,
            secret,
            password: formData.password,
          };
          medplum
            .post('auth/setpassword', body)
            .then(() => setSuccess(true))
            .catch((err) => {
              if (err?.message === 'Password found in breach database (password)') {
                const theOutcome = normalizeOperationOutcome(err);
                if (theOutcome) {
                  theOutcome.text = t('auth.password-must-have') as Narrative;
                  if (theOutcome?.issue?.[0]?.details) {
                    theOutcome.issue[0].details.text = t('auth.password-must-have');
                  }
                }
                setOutcome(normalizeOperationOutcome(theOutcome));
              } else {
                setOutcome(normalizeOperationOutcome(err));
              }
            });
        }}
      >
        <Center sx={{ flexDirection: 'column' }}>
          <img src="../../img/soulfi-favicon.png" style={{ width: '50px' }} />
          <Title>{t('auth.set-password')}</Title>
        </Center>
        {!success && (
          <Stack>
            <PasswordInput
              name="password"
              label={t('auth.new-password')}
              required={true}
              error={getErrorsForInput(outcome, 'password')}
            />
            <PasswordInput
              name="confirmPassword"
              label={t('auth.confirm-new-password')}
              required={true}
              error={getErrorsForInput(outcome, 'confirmPassword')}
            />
            <Group position="right" mt="xl">
              <Button type="submit">{t('auth.set-password')}</Button>
            </Group>
          </Stack>
        )}
        {success && (
          <div data-testid="success">
            {t('auth.password-set')}. {t('auth.you-can-now')}&nbsp;
            <MedplumLink to="/signin">{t('auth.sign-in-to-dashboard')}</MedplumLink>. <br />
            <br /> {t('auth.if-you-are-user')}.
          </div>
        )}
      </Form>
    </Document>
  );
}
