/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  ActionIcon,
  Badge,
  Box,
  Button,
  CopyButton,
  Flex,
  Modal,
  Paper,
  Text,
  TextInput,
  Tooltip,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { IconCheck, IconCopy, IconLink, IconMailShare, IconPlus, IconSend, IconX } from '@tabler/icons-react';
import React, { useState } from 'react';
// import { getInviteLink } from '../fhirApi';
import { showNotification } from '@mantine/notifications';
import { MedplumClient } from '@medplum/core';
import { useTranslation } from 'react-i18next';
import { useAuthMeStore } from '../../../store/useAuthMeStore';
import { BundleEntry, Consent, Identifier } from '@medplum/fhirtypes';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../../queries/query-keys';
import { getConfig } from '../../../config';

interface addInviterProps {
  open?: any;
  closePopup?: any;
  currentProduct: any;
  total: number;
}

export const InviteMember: React.FC<addInviterProps> = ({ open, closePopup }) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const medplum: MedplumClient = useMedplum();
  const queryClient = useQueryClient();
  const { inviteMemberByEmail } = getConfig();
  const orgId = localStorage.getItem('orgId') ?? useAuthMeStore((s) => s.orgId);
  // const orgName = localStorage.getItem('orgName') ?? '';

  const organization = useAuthMeStore((s) => s.organization);
  // const [inviteLink, setinviteLink] = useState();

  const [inputVal, setInputVal] = useState<string>('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [emails, setEmails] = useState<string[]>([]);
  const [isInviteLoading, setIsInviteLoading] = useState(false);

  const handleEnterKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleSetEmails();
    }
  };

  const handleEmailChange = (value: string) => {
    setInputVal(value);
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value); // Email regex
    setIsValidEmail(isValid);
  };

  const handleSetEmails = async () => {
    if (inputVal.length > 0) {
      if (emails.some((e) => e === inputVal)) {
        showNotification({ message: t('users.email-already-added'), color: 'yellow' });
      } else {
        const newEmails = [...emails, inputVal];
        const consents = await handleCheckConsents(newEmails);

        const isAlreadyInvitedArr = checkAlreadyInvited(consents);

        if (isAlreadyInvitedArr?.some((inv: any) => inv?.invited === true)) {
          isAlreadyInvitedArr?.map((x: any) => {
            const message =
              x?.status === 'active'
                ? t('users.email-already-in-organization', { email: x?.email })
                : x?.status === 'draft'
                  ? t('users.email-already-invited', { email: x?.email })
                  : undefined;
            if (message) {
              showNotification({ message, color: 'red' });
            }
          });

          setEmails([]);
          setInputVal('');
        } else {
          setEmails([...emails, inputVal]);
          setInputVal('');
        }
      }
    }
  };

  const handleRemoveEmail = (val: string) => {
    const filteredEmails = emails.filter((f) => f !== val);
    setEmails(filteredEmails);
  };

  const handleCreateConsents = async () => {
    const bundleEntries: BundleEntry<Consent>[] = emails?.map((eml) => {
      return {
        request: {
          method: 'POST',
          url: '/Consent',
        },
        resource: {
          resourceType: 'Consent',
          status: 'draft',
          policyRule: {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/consentpolicycodes',
                code: 'cric',
                display: 'Common Rule Informed Consent',
              },
            ],
          },
          category: [
            {
              coding: [
                {
                  system: 'http://loinc.org',
                  code: '59284-0',
                  display: 'Patient Consent',
                },
              ],
            },
          ],
          scope: {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/consentscope',
                code: 'patient-privacy',
                display: 'Privacy Consent',
              },
            ],
          },
          organization: [
            {
              reference: `Organization/${orgId}`,
              display: organization?.name,
            },
          ],
          identifier: [
            {
              system: 'patient-email',
              value: eml,
            },
          ],
        },
      };
    });

    await medplum.executeBatch({
      resourceType: 'Bundle',
      type: 'transaction',
      entry: bundleEntries,
    });
  };

  const handleCheckConsents = async (newEmails: string[]) => {
    const bundleEntries: BundleEntry<Consent>[] = newEmails?.map((e) => {
      return {
        request: {
          method: 'GET',
          url: `Consent?organization=Organization/${orgId}&identifier=${e}`,
        },
      };
    });

    return await medplum
      .executeBatch(
        {
          resourceType: 'Bundle',
          type: 'batch',
          entry: [...bundleEntries],
        },
        {
          cache: 'no-cache',
        }
      )
      .then((resp) => resp?.entry?.map((e) => e?.resource));
  };

  const checkAlreadyInvited = (consents: any) => {
    return consents?.map((c: any) => {
      if (c?.entry?.[0]?.resource?.id) {
        return {
          invited: true,
          status: c?.entry?.[0]?.resource?.status,
          email: c?.entry?.[0]?.resource?.identifier?.find((ident: Identifier) => ident?.system === 'patient-email')
            ?.value,
        };
      } else
        return {
          invited: false,
          status: undefined,
          email: undefined,
        };
    });
  };

  const handleInviteMemberByEmail = async () => {
    setIsInviteLoading(true);

    if (!!orgId) {
      await medplum
        .executeBot(inviteMemberByEmail, {
          emails,
          // organizationName: orgName,
          organizationId: orgId,
          langCode: localStorage.getItem('selectedLanguage') ?? 'de',
        })
        .then(async () => {
          await handleCreateConsents().then(async () => {
            await queryClient.resetQueries(QueryKeys.GET_CONSENTS);
            setIsInviteLoading(false);
            closePopup();
            setEmails([]);
            setInputVal('');
            showNotification({
              color: 'green',
              message: t(`users.invitation-email-sent-to`, { emails: emails?.join(', ') }),
            });
          });
        })
        .catch((error: any) => {
          setIsInviteLoading(false);
          closePopup();
          setEmails([]);
          setInputVal('');
          showNotification({ color: 'red', message: error.message });
        });
    }
  };

  return (
    <Modal size="36rem" centered opened={open} onClose={closePopup} title={t('users.invite-new-members')}>
      <Box p={4}>
        <Flex direction="column" w={'100%'}>
          <Flex direction="row" align="center" w={'100%'}>
            <IconLink size={18} color={'#00658B'} />
            <Text color={theme.primaryColor} size="sm" ml={4} fw="bold">
              {t('users.invite-via-link')}
            </Text>
          </Flex>
          <Text color="dimmed" size="sm">
            {t('users.invite-via-link-description')}
          </Text>
        </Flex>
        <Flex my={20} direction="row" align="center" w={'100%'}>
          <Paper bg="#228be61a" p="xs">
            <Text size={'sm'} c="blue">{`https://web.soulfi.com/register?organizationId=${orgId}`}</Text>
          </Paper>
          <CopyButton value={`https://web.soulfi.com/register?organizationId=${orgId}`} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip label={copied ? t('common.copied') : t('common.copy')} withArrow position="right">
                <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                  {copied ? <IconCheck style={{ width: rem(16) }} /> : <IconCopy style={{ width: rem(16) }} />}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Flex>
        <Flex direction="column" w={'100%'}>
          <Flex direction="row" align="center" w={'100%'}>
            <IconMailShare size={18} color={'#00658B'} />
            <Text color={theme.primaryColor} size="sm" ml={4} fw="bold">
              {t('users.invite-by-mail')}
            </Text>
          </Flex>
          <Text color="dimmed" size="sm">
            {t('users.invite-by-mail-description')}
          </Text>
        </Flex>
        <Flex my={12} direction="row" align="center" w={'100%'} wrap={'wrap'}>
          {emails.map((e) => (
            <Badge variant="light" size="lg" mr={12} mb={4} style={{ textTransform: 'none' }}>
              <Flex w={'100%'} align="center" justify="space-between">
                <Text style={{ fontSize: 12, fontWeight: 'normal' }} variant="text">
                  {e}
                </Text>
                <ActionIcon
                  style={{ borderRadius: '50%' }}
                  ml={12}
                  color="primary"
                  variant="light"
                  size="xs"
                  onClick={() => handleRemoveEmail(e)}
                >
                  <IconX size={12} />
                </ActionIcon>
              </Flex>
            </Badge>
          ))}
        </Flex>
        <Flex direction="row" align="center" w={'100%'}>
          <TextInput
            value={inputVal}
            onChange={(e) => handleEmailChange(e.target.value)}
            onKeyDown={handleEnterKeyPress}
            name="inviteEmail"
            placeholder={t('common.please-enter-email-address')}
            type="email"
            size="sm"
            w={'70%'}
            mr={12}
          />
          <Button
            disabled={!isValidEmail || inputVal.length <= 0}
            leftIcon={<IconPlus size={14} />}
            variant="light"
            size="sm"
            onClick={handleSetEmails}
          >
            {t('common.add')}
          </Button>
        </Flex>
        <Flex mt={20} direction="row" align="center" justify="flex-end" w={'100%'}>
          <Flex align="center" direction="row">
            <Button variant="outline" onClick={closePopup}>
              {t('common.close')}
            </Button>
            <Button
              ml={12}
              disabled={emails.length <= 0}
              loading={isInviteLoading}
              leftIcon={<IconSend size={20} />}
              onClick={handleInviteMemberByEmail}
            >
              {t('common.invite')}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};
