import { UseMutationOptions, useMutation } from 'react-query';
import { getConfig } from '../../config';
import axios from 'axios';
import { showNotification } from '@mantine/notifications';

export interface IRegisterForm {
  //Step 1
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  organizationId: string;
}

export const useRegisterUser = (
  options?: Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn' | 'mutationKey'>
) => {
  const { projectId, ovokBaseUrl } = getConfig();

  return useMutation(async ({ email, firstName, lastName, password, organizationId }: IRegisterForm) => {
    try {
      const resp = await axios
        .post(
          `${ovokBaseUrl}/auth/signup`,
          {
            resourceType: 'Patient',
            sendDefaultEmail: false,
            password,
            firstName,
            email,
            lastName,
            projectId,
            // clientId,
            organizationId,
          },
          {
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
            },
          }
        )
        
      return resp.data;
    } catch (error: any) {
      showNotification({ message: error?.response?.data?.message?.message, color: 'red' })
   }
  }, options);
};
