import React, { useState } from 'react';
import { Grid, Box, Card, Button, Divider, LoadingOverlay } from '@mantine/core';
import { OrganizationForm } from './OrganizationForm';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetOrganizationById } from '../../../queries/organization/useGetOrganizationById';
import { Account, Address, Organization } from '@medplum/fhirtypes';
import {
  IUpgradeStripeSubscriptionBody,
  useUpdateOrganization,
} from '../../../queries/organization/useUpdateOrganization';
import { showNotification } from '@mantine/notifications';
import { useGetStripeProducts } from '../../../queries/stripe/useGetStripeProducts';
import { useGetAccount } from '../../../queries/account/useGetAccount';
import { useGetStripeSubscriptionById } from '../../../queries/stripe/useGetSubscriptionById';
import { useAuthMeStore } from '../../../store/useAuthMeStore';

export interface UpdateOrganizationFormData {
  orgName: string;
  workAddress: Address;
  billingAddress: Address;
  phoneNo: string;
  firstName: string;
  lastName: string;
  email: string;
  contactNo: string;
}

export const OrganizationEdit = () => {
  const { t } = useTranslation();
  const params = useParams();

  const isCompanyAdmin = useAuthMeStore((s) => s.isCompanyAdmin) ?? localStorage.getItem('companyAdmin') === 'true';
  const organizationId = useAuthMeStore((s) => s.orgId) ?? localStorage.getItem('orgId');
  const orgId = isCompanyAdmin ? organizationId : params.organizationId;

  const [formData, setFormData] = useState<UpdateOrganizationFormData>();
  const [upgradeProducts, setUpgradeProducts] = useState<any[]>([]);
  const [isUpgradePlanOpen, setIsUpgradePlanOpen] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = useState<any>();
  const [subscriptionId, setSubscriptionId] = useState<string | undefined>();
  const [currentProductId, setCurrentProductId] = useState<string | undefined>();
  const [planIds, setPlanIds] = useState<{ productId: string | undefined; priceId: string | undefined }>({
    productId: undefined,
    priceId: undefined,
  });

  const { data: accountData, isLoading: isLoadingAccountData } = useGetAccount(orgId as string, {
    onSuccess: (resp) => {
      setSubscriptionId(resp?.identifier?.find((r) => r?.system === 'subscriptionId')?.value);
      setCurrentProductId(resp?.identifier?.find((r) => r?.system === 'productId')?.value);
    },
  });

  const { data: subscriptionData, isLoading: isLoadingSubs } = useGetStripeSubscriptionById(subscriptionId as string, {
    enabled: !!subscriptionId,
  });

  const {
    data: orgData,
    isLoading: isLoadingOrgData,
    isFetched: isOrgDataFetched,
  } = useGetOrganizationById(orgId as string, {
    enabled: !!orgId,
    onSuccess: (data: Organization | undefined) => {
      if (data) {
        setFormData({
          firstName: data?.contact?.[0]?.name?.given?.[0] ?? '',
          lastName: data?.contact?.[0]?.name?.family ?? '',
          email: data?.contact?.[0]?.telecom?.find((t: any) => t?.system === 'email')?.value ?? '',
          orgName: data?.name ?? '',
          workAddress: data?.address?.find((x) => x?.use === 'work') ?? {},
          billingAddress: data?.address?.find((x) => x?.use === 'billing') ?? {},
          phoneNo: data?.telecom?.[0]?.value ?? '',
          contactNo: data?.contact?.[0]?.telecom?.find((t: any) => t?.system === 'phone')?.value ?? '',
        });
      }
    },
  });

  // Fetch Stripe Products
  useGetStripeProducts({
    enabled: isOrgDataFetched && !!currentProductId,
    onSuccess: (resp) => {
      const currentPlan = resp?.data?.find((p: any) => p?.id === currentProductId);

      const upperPlans = resp?.data
        ?.filter((s: any) => Number(s?.metadata?.licensesAmount) > Number(currentPlan?.metadata?.licensesAmount))
        .sort((a: any, b: any) => Number(a?.metadata?.licensesAmount) - Number(b?.metadata?.licensesAmount));

      setUpgradeProducts(upperPlans);
      setCurrentProduct(currentPlan);
      setPlanIds({
        productId: upperPlans?.[0]?.id,
        priceId: upperPlans?.[0]?.default_price,
      });
    },
  });

  const { mutateAsync: updateOrganization, isLoading: isLoadingUpdateOrg } = useUpdateOrganization({
    onSuccess: async () => {
      showNotification({ color: 'green', message: t('settings.organization-update-success') });
    },
    onSettled: () => {
      if (isUpgradePlanOpen) {
        window.location.reload();
      }
    },
  });

  const handleSubmit = async () => {
    const body: Organization = {
      ...orgData,
      resourceType: 'Organization',
      name: formData?.orgName,
      telecom: [
        {
          system: 'phone',
          value: formData?.phoneNo,
        },
      ],
      address: [
        {
          use: 'work',
          ...formData?.workAddress,
        },
        {
          use: 'billing',
          ...formData?.billingAddress,
        },
      ],
      contact: [
        {
          name: {
            given: [formData?.firstName ?? ''],
            family: formData?.lastName,
          },
          telecom: [
            {
              system: 'phone',
              value: formData?.contactNo,
            },
            {
              system: 'email',
              value: formData?.email,
            },
          ],
        },
      ],
    };

    const upgradePlanBody: IUpgradeStripeSubscriptionBody = {
      isUpgradePlanOpen,
      currentSubsItemId: subscriptionData?.items?.data?.[0]?.id,
      subscriptionId: subscriptionData?.id,
      priceId: planIds?.priceId as string,
      productId: planIds?.productId as string,
    };

    await updateOrganization({ body, upgradePlanBody, currentAccount: accountData as Account });
  };

  if (isLoadingUpdateOrg || isLoadingOrgData || isLoadingAccountData || isLoadingSubs) {
    return <LoadingOverlay visible />;
  }

  console.log({ planIds });

  return (
    <Box mih={'93vh'} miw={'100%'} px="lg" py="sm" sx={{ paddingBottom: '0px !important' }}>
      <Grid mb="md">
        <Grid.Col span={12} lg={9} sx={{ display: 'block', margin: 'auto' }}>
          <Card withBorder radius="md" sx={{ padding: '0px !important', boxShadow: '0px 1px 2px 0px #0000001A' }}>
            <Card.Section p="lg" sx={{ padding: '2rem 2rem !important' }}>
              {formData && (
                <OrganizationForm
                  isUpgradePlanOpen={isUpgradePlanOpen}
                  setIsUpgradePlanOpen={setIsUpgradePlanOpen}
                  currentPlan={currentProduct}
                  planIds={planIds}
                  setPlanIds={(val: { productId: string | undefined; priceId: string | undefined }) => setPlanIds(val)}
                  formData={formData}
                  upgradeProducts={upgradeProducts}
                  setFormData={(val: UpdateOrganizationFormData) => setFormData(val)}
                />
              )}
              <Divider my="sm" />
              <Grid sx={{ justifyContent: 'end', margin: '20px 0' }}>
                <Button ml={12} loading={isLoadingUpdateOrg} onClick={handleSubmit}>
                  {t('common.save')}
                </Button>
              </Grid>
            </Card.Section>
          </Card>
        </Grid.Col>
      </Grid>
    </Box>
  );
};
