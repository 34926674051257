import { UseMutationOptions, useMutation } from 'react-query';
import { useMedplum } from '@medplum/react';
import { Patient, Reference } from '@medplum/fhirtypes';
import { getConfig } from '../../config';

export interface IChangeUserGroupProps {
  sourceGroup: SourceGroup[];
  targetGroupId: string;
}

export interface SourceGroup {
  sourceGroupId: string;
  patientRefs: Reference<Patient>[];
}

export const useChangeUsersGroup = (
  options?: Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn' | 'mutationKey'>
) => {
  const medplum = useMedplum();
  const {changeUsersGroup} = getConfig()

  return useMutation(async (props: IChangeUserGroupProps) => {
    return medplum.executeBot(changeUsersGroup, {
      ...props,
    });
  }, options);
};
