import { ActionIcon, Center, Flex, Grid, Title, createStyles } from '@mantine/core';
import { SignInForm } from './patient/auth/SignInForm';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconChevronLeft } from '@tabler/icons-react';
import LanguageSwitchMenu from './components/LanguageSwitchMenu';

const useStyles = createStyles((theme) => ({
  hiddenMobile: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  paddingMobile: {
    [theme.fn.smallerThan('sm')]: {
      padding: '25px !important',
    },
  },

  primaryTitle: {
    textAlign: 'left',
    color: '#000',
    float: 'left',
    fontSize: '34px',
    lineHeight: '1.1',
    fontWeight: 300,
    marginBottom: '8px',
  },

  secondaryTitle: {
    color: '#272D41',
    float: 'left',
    fontSize: '20px',
    lineHeight: '1.1',
    fontWeight: 700,
    marginBottom: '8px',
  },

  subTitle: {
    color: '#94a3b8',
    marginTop: '10px',
    letterSpacing: '-0.025em',
    fontSize: '14px',
    fontWeight: 300,
  },
}));

export function SignInPage(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { classes } = useStyles();
  const [step, setStep] = useState<'email' | 'password'>('email');
  const selectedLang = localStorage.getItem('selectedLanguage') || 'de';
  const [langvalue, setLangValue] = useState(selectedLang?.toUpperCase() || 'DE');
  const [langImage, setLangImage] = useState(
    selectedLang === 'en' ? '../../img/dashboard-assets/us-Icon.svg' : '../../img/dashboard-assets/german.png'
  );
  const [langMenuOpened, setLangMenuOpened] = useState(false);

  return (
    <Grid style={{ background: '#FFF' }} className="signin">
      <Grid.Col md={6} lg={6} className={classes.paddingMobile} sx={{ display: 'block', margin: 'auto' }}>
        <Center
          p={{ xs: 'lg', md: 'xs' }}
          style={{
            alignContent: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            width: '100%',
          }}
        >
          <Flex style={{ position: 'absolute', right: 10, top: 10 }}>
            <LanguageSwitchMenu
              langvalue={langvalue}
              langImage={<img src={langImage} style={{ width: '20px' }} />}
              setLangImage={setLangImage}
              setLangValue={setLangValue}
              langMenuOpened={langMenuOpened}
              setLangMenuOpened={setLangMenuOpened}
            />
          </Flex>
          <SignInForm
            onSuccess={() => navigate('/')}
            onForgotPassword={() => navigate('/resetpassword')}
            // register new user is disabled
            // onRegister={config.registerEnabled ? () => navigate('/register') : undefined}
            disableGoogleAuth
            login={searchParams.get('login') || undefined}
            projectId={searchParams.get('project') || undefined}
            step={step}
            setFormStep={setStep}
          >
            <Center
              display="flex"
              m={{ xs: 'lg', md: 'xs' }}
              style={{ flexDirection: 'column', minWidth: '350px', position: 'relative' }}
            >
              {step === 'password' && (
                <ActionIcon onClick={() => setStep('email')} style={{ position: 'absolute', left: -10, top: -10 }}>
                  <IconChevronLeft />
                </ActionIcon>
              )}
              <img src="../img/soulfi-favicon.png" style={{ width: '50px' }} />
              <Title className={classes.secondaryTitle} variant="h3" fw={700} mb={3} mt={20}>
                {t('auth.sign-in-to')} Soulfi!
              </Title>
              {searchParams.get('project') === 'new' && <div>{t('auth.sign-in-again-to-create-a-new-project')}</div>}
            </Center>
          </SignInForm>
        </Center>
      </Grid.Col>
    </Grid>
  );
}
