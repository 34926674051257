/* eslint-disable @typescript-eslint/no-unused-vars /
/ eslint-disable @typescript-eslint/explicit-function-return-type */
import { Anchor, Box, Button, Center, Grid, Group, PasswordInput, Stack, Text, TextInput } from '@mantine/core';
import { normalizeOperationOutcome } from '@medplum/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form } from '../../../../react/src/Form/Form';
import { OperationOutcomeAlert } from '../../../../react/src/OperationOutcomeAlert/OperationOutcomeAlert';
import { getErrorsForInput, getIssuesForExpression } from '../../../../react/src/utils/outcomes';
import { initRecaptcha } from '../../../../react/src/utils/recaptcha';
import { useNavigate } from 'react-router-dom';
import { IRegisterForm, useRegisterUser } from '../../queries/auth/useRegister';
import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';

export interface NewUserFormProps {
  readonly projectId: string;
  readonly clientId?: string;
  readonly googleClientId?: string;
  readonly recaptchaSiteKey?: string;
  readonly children?: React.ReactNode;
  readonly handleAuthResponse: (response: any) => void;
  checkAlert?: any;
  isLoading?: any;
}

export function NewUserForm(props: NewUserFormProps): JSX.Element {
  // const googleClientId = getGoogleClientId(props.googleClientId);
  const recaptchaSiteKey = props.recaptchaSiteKey;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const searchParams = new URLSearchParams(window.location.search);
  const organizationId: any = searchParams.get('organizationId');
  const email: any = searchParams.get('email');

  const [outcome, setOutcome] = useState<any>();
  const issues = getIssuesForExpression(outcome, undefined);
  const [loginFormData, setLoginFormData] = useState({
    email: '',
    password: '',
  });

  console.log({ loginFormData });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (recaptchaSiteKey) {
      initRecaptcha(recaptchaSiteKey);
    }
  }, [recaptchaSiteKey]);

  // function handleAuthResponse(response: any): void {
  //   if (response.code) {
  //     medplum.processCode(response.code).catch((err: any) => console.error(err.message));
  //   } else if (response) {
  //     setOutcome('');
  //     createPatient(response.login);
  //     serCheckAlert(true);
  //     setIsLoading(false);
  //   }
  // }

  // const fetchToken = async (code: string) => {
  //   const formBody = new URLSearchParams();
  //   formBody.set('grant_type', 'authorization_code');
  //   formBody.set('code', code);
  //   formBody.set('client_id', '');
  //   formBody.set('redirect_uri', window.location.protocol + '//' + window.location.host + '/');
  //   const codeVerifier = sessionStorage.getItem('codeVerifier');
  //   if (codeVerifier) {
  //     formBody.set('code_verifier', codeVerifier);
  //   }
  //   const response = await fetch(`https://dashboard.dev.ovok.com/api/oauth2/token`, {
  //     method: 'POST',
  //     headers: { 'Content-Type': ContentType.FORM_URL_ENCODED },
  //     body: formBody.toString(),
  //     credentials: 'include',
  //   });
  //   const tokens = await response.json();
  //   const token = tokens.access_token;

  //   return token;
  // };

  // const createPatient = (login: any) => {
  //   const projectId = import.meta.env.MEDPLUM_PROJECT_ID;
  //   newPatient(login, projectId)
  //     .then(async (response: any) => {
  //       if (response.data.code) {
  //         serCheckAlert(true);
  //         setIsLoading(false);

  //         registerNewUser(await fetchToken(response.data.code));
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.error('Error fetching data:', error);
  //     });
  // };

  // const registerNewUser = (accessToken: string) => {
  //   const projectId = import.meta.env.MEDPLUM_PROJECT_ID;
  //   registerUser(accessToken, loginFormData.email, projectId, organizationId)
  //     .then((response: any) => {
  //       medplum
  //         .startLogin({
  //           //...baseLoginRequest,
  //           email: loginFormData.email,
  //           password: loginFormData.password,
  //           projectId: projectId,
  //           remember: false,
  //         })
  //         .then((res: any) => {
  //           medplum.processCode(res.code);
  //           localStorage.setItem('isLoggedIn', 'true');
  //         })
  //         .catch((err) => setOutcome(normalizeOperationOutcome(err)));
  //     })
  //     .catch((error: any) => {
  //       console.error('Error fetching data:', error);
  //     });
  // };

  const { mutateAsync: registerUser, isLoading } = useRegisterUser({
    onSuccess: (resp) => {
      if (resp) {
        showNotification({ message: t('common.success'), color: 'green' });
        navigate('/auth/user-invite');
      }
    },
    onError: (err: any) => {
      setOutcome(normalizeOperationOutcome(err.message));
    },
  });

  const handleSubmit = async (formData: Record<string, string>) => {
    const body: IRegisterForm = {
      organizationId: organizationId as string,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: email ?? formData.email,
      password: formData.password,
    };

    await registerUser(body);
  };

  return (
    <>
      <Form style={{ maxWidth: 400 }} onSubmit={async (formData: Record<string, string>) => handleSubmit(formData)}>
        <Center sx={{ flexDirection: 'column' }}>{props.children}</Center>
        <OperationOutcomeAlert issues={issues} />
        {/* {googleClientId && (
          <>
            <Group position="center" p="xl" style={{ height: 70 }}>
              <GoogleButton
                googleClientId={googleClientId}
                handleGoogleCredential={async (response: GoogleCredentialResponse) => {
                  try {
                    handleAuthResponse(
                      await medplum.startGoogleLogin({
                        googleClientId: response.clientId,
                        googleCredential: response.credential,
                        createUser: true,
                      })
                    );
                  } catch (err) {
                    setOutcome(normalizeOperationOutcome(err));
                  }
                }}
              />
            </Group>
            <Divider label="or" labelPosition="center" my="lg" />
          </>
        )} */}
        {searchParams.get('organizationName') && (
          <Grid sx={{ margin: '20px 0', display: 'block' }}>
            <Text sx={{ fontWeight: 500 }}>Organization</Text>
            <Text>{searchParams.get('organizationName')}</Text>
          </Grid>
        )}
        <Stack spacing="xl">
          <TextInput
            name="email"
            type="email"
            value={email}
            disabled={email ? true : false}
            label={t('common.email')}
            placeholder="name@domain.com"
            required={true}
            error={getErrorsForInput(outcome, 'email')}
            onChange={handleInputChange}
          />
          <TextInput
            name="firstName"
            type="text"
            label={t('common.first-name')}
            placeholder={t('common.first-name')}
            required={true}
            autoFocus={true}
            error={getErrorsForInput(outcome, 'firstName')}
          />
          <TextInput
            name="lastName"
            type="text"
            label={t('common.last-name')}
            placeholder={t('common.last-name')}
            required={true}
            error={getErrorsForInput(outcome, 'lastName')}
          />

          <PasswordInput
            name="password"
            label={t('common.password')}
            autoComplete="off"
            required={true}
            minLength={8}
            error={getErrorsForInput(outcome, 'password')}
            onChange={handleInputChange}
          />
          <input type="hidden" name="remember" value="false" />
          <input type="hidden" name="recaptchaToken" value="" />
          <input type="hidden" name="organizationId" value={organizationId} />
          <Text color="dimmed" size="xs">
            {t('auth.by-clicking-agree')}{' '}
            <Anchor target="_blank" href="https://www.iubenda.com/privacy-policy/33250273">
              {t('auth.privacy-policy')}
            </Anchor>{' '}
            {t('common.and')}{' '}
            <Anchor target="_blank" href="https://www.iubenda.com/nutzungsbedingungen/33250273">
              {t('auth.terms-and-conditions')}
            </Anchor>
            .
          </Text>
        </Stack>
        <Group position="apart" mt="xl" noWrap>
          {/* <Checkbox name="remember" label="Remember me" size="xs" /> */}
          <Box />
          <Button loading={isLoading} type="submit">
            {t('auth.create-account')}
          </Button>
        </Group>
      </Form>
      {/* {checkAlert ? (
        <Alert icon={<IconCircleCheck size={16} />} color="success" style={{ marginTop: 10 }}>
          You are registered successfully. Logging you in, please wait...
          <Text
            sx={{ padding: '0 5px', textDecoration: 'underline', color: '#1c7ed6' }}
            component={Link}
            to={`/signin`}
          >
            click here
          </Text>
          to Sign in
        </Alert>
      ) : (
        ''
      )} */}
    </>
  );
}
