import { UseMutationOptions, useMutation } from 'react-query';
import { BundleEntry, CodeableConcept, Goal, Group, GroupMember } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { v4 as uuid } from 'uuid';
import { useAuthMeStore } from '../../store/useAuthMeStore';

export interface ICreateUpdateGroupProps {
  type: 'create' | 'update';
  groupBody: {
    id?: string;
    name: string;
    // weekTarget: string;
    organizationId: string | null;
  };
  goals: {
    isChecked: boolean;
    id?: string;
    type: 'Activity' | 'Steps' | 'Stand Up' | 'Mindful';
    unit: 'calories' | 'steps' | 'hours' | 'sessions';
    code: string;
    groupName: string;
    target: number;
  }[];
  groupId: string | undefined;
  members: GroupMember[];
  groupCode: CodeableConcept | undefined;
}

export const useCreateUpdateGroupAndGoals = (
  options?: Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn' | 'mutationKey'>
) => {
  const medplum = useMedplum();
  const generatedGroupId = uuid();
  const generatedGoalId = uuid();
  const orgId = useAuthMeStore((s) => s.orgId);

  return useMutation(async (props: ICreateUpdateGroupProps) => {
    const generateGroupMembers = (): GroupMember[] => {
      if (props.type === 'update') {
        return [...props.members];
      } else {
        return [];
      }
    };

    const generateGroupCode = (): CodeableConcept | undefined => {
      if (props.type === 'update') {
        return props.groupCode;
      } else {
        return undefined;
      }
    };

    const groupBody: Group = {
      resourceType: 'Group',
      name: props.groupBody.name,
      id: props.type === 'update' ? props.groupId : undefined,
      type: 'person',
      actual: true,
      // extension: [
      //   {
      //     url: 'http://week.target',
      //     valueString: props.groupBody.weekTarget.toString(),
      //   },
      // ],
      identifier: [
        {
          value: `Organization/${props.groupBody.organizationId}`,
        },
      ],
      managingEntity: {
        reference: `Organization/${props.groupBody.organizationId}`,
      },
      code: generateGroupCode(),
      member: generateGroupMembers(),
    };

    const goals: Goal[] = props.goals.map((g) => {
      return {
        resourceType: 'Goal',
        id: props.type === 'update' ? g.id : undefined,
        lifecycleStatus: g.isChecked ? 'active' : 'cancelled',
        identifier: [
          {
            value: `Organization/${orgId}`,
          },
        ],
        category: [
          {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/goal-category',
                code: 'behavioral',
                display: 'Behavioral',
              },
            ],
          },
        ],
        description: {
          coding: [
            {
              code: `${g.type} (${g.unit}})`,
              display: `${g.type} (${g.unit})`,
            },
          ],
        },
        subject: {
          reference: props.groupId ? `Group/${props.groupId}` : `urn:uuid:${generatedGroupId}`,
          display: g.groupName ?? '',
        },
        // startDate: new Date().toISOString(),
        target: [
          {
            measure: {
              coding: [
                {
                  code: g.code,
                  display: g.code,
                },
              ],
            },
            detailQuantity: {
              value: Number(g.target),
              comparator: '>=',
              unit: g.unit,
            },
          },
        ],
      };
    });

    const goalEntries: BundleEntry<Goal>[] = goals.map((x: Goal) => {
      return {
        fullUrl: props.type === 'update' ? '' : `urn:uuid:${generatedGoalId}`,
        request: {
          method: props.type === 'update' ? 'PUT' : 'POST',
          url: props.type === 'update' ? `/Goal/${x.id}` : '/Goal',
        },
        resource: {
          ...x,
        },
      };
    });

    const bundleEntries: BundleEntry<Group | Goal>[] = [
      {
        fullUrl: props.type === 'update' ? '' : `urn:uuid:${generatedGroupId}`,
        request: {
          method: props.type === 'update' ? 'PUT' : 'POST',
          url: props.type === 'update' ? `/Group/${props.groupId}` : '/Group',
        },
        resource: {
          ...groupBody,
        },
      },
      ...goalEntries,
    ];

    return medplum.executeBatch(
      {
        resourceType: 'Bundle',
        type: 'transaction',
        entry: bundleEntries,
      },
      {
        cache: 'reload',
      }
    );
  }, options);
};
