import { Button, Center, Flex, List, Loader, Modal, Text, ThemeIcon, useMantineTheme } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useGetConsents } from '../../../queries/consent/useGetConsents';
import { useAuthMeStore } from '../../../store/useAuthMeStore';
import { useTranslation } from 'react-i18next';
import { useMedplum } from '@medplum/react';
import { showNotification } from '@mantine/notifications';
import { getConfig } from '../../../config';

interface Props {
  isOpened: boolean;
  onClose: () => void;
}

const InvitedUsersModal = ({ isOpened, onClose }: Props) => {
  const { t } = useTranslation();
  const medplum = useMedplum();
  const theme = useMantineTheme();
  const orgId = useAuthMeStore((s) => s.orgId) ?? localStorage.getItem('orgId');
  // const orgName = useAuthMeStore((s) => s.organization?.name);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [resendEmailVal, setResendEmailVal] = useState<string | undefined>();
  const { inviteMemberByEmail } = getConfig();

  const { data, isLoading } = useGetConsents('draft', orgId);

  const handleResendInvitationMail = async (email: string | undefined) => {
    if (!email) return alert('Email is not provided');
    setIsResendLoading(true);
    setResendEmailVal(email);

    await medplum
      .executeBot(inviteMemberByEmail, {
        emails: [email],
        // organizationName: `${orgName}`,
        organizationId: orgId,
        langCode: localStorage.getItem('selectedLanguage') ?? 'de',
      })
      .then(async () => {
        showNotification({
          color: 'green',
          message: t(`users.invitation-email-sent-to`, { emails: [email]?.join(', ') }),
        });
      })
      .catch((error: any) => {
        showNotification({ color: 'red', message: error.message });
      })
      .finally(() => {
        setIsResendLoading(false);
      });
  };

  return (
    <>
      <Modal centered opened={isOpened} onClose={onClose} size="md" title={t('users.invited-users')}>
        {isLoading ? (
          <Center p={20}>
            <Loader />
          </Center>
        ) : (
          <List
            spacing="xs"
            size="sm"
            icon={
              <ThemeIcon color="teal" size={24} radius="xl">
                <IconCircleCheck size="1rem" />
              </ThemeIcon>
            }
          >
            {data && data?.length <= 0 && (
              <Center>
                <Text size="sm" color="dimmed">
                  {t('common.no-results')}
                </Text>
              </Center>
            )}
            {data?.map((consent) => (
              <Flex
                key={consent?.id}
                direction="row"
                w="100%"
                align="center"
                justify="space-between"
                sx={{ borderBottom: `1px solid ${theme.colors.gray[1]}` }}
              >
                <List.Item sx={{ padding: 12 }}>
                  <Text size="sm">{consent?.identifier?.find((x) => x?.system === 'patient-email')?.value}</Text>
                  <Flex direction="row" align="center">
                    <Text size="xs" style={{ fontStyle: 'italic' }}>
                      {t('users.invitation-email-sent')}
                    </Text>
                    <Button
                      loading={
                        resendEmailVal === consent?.identifier?.find((x) => x?.system === 'patient-email')?.value &&
                        isResendLoading
                      }
                      onClick={() =>
                        handleResendInvitationMail(
                          consent?.identifier?.find((x) => x?.system === 'patient-email')?.value
                        )
                      }
                      size="xs"
                      variant="white"
                    >
                      <Text size="xs" style={{ fontStyle: 'italic', textDecorationLine: 'underline' }}>
                        {t('common.resend')}
                      </Text>
                    </Button>
                  </Flex>
                </List.Item>
                {/* <Badge color={'yellow'}>{t('common.pending')}</Badge> */}
              </Flex>
            ))}
          </List>
        )}

        <Flex mt={20} w="100%" align="center" justify="flex-end">
          <Button variant="outline" onClick={onClose}>
            {t('common.close')}
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default InvitedUsersModal;
