import { UseMutationOptions, useMutation } from 'react-query';
import { useMedplum } from '@medplum/react';
import { getConfig } from '../../config';

export interface IDeactivateOrganizationProps {
  organizationId: string;
}

export const useDeactivateOrganization = (
  options?: Omit<UseMutationOptions<any, unknown, any, unknown>, 'mutationFn' | 'mutationKey'>
) => {
  const medplum = useMedplum();
  const { deactivateOrganization } = getConfig();

  return useMutation(async ({ organizationId }: IDeactivateOrganizationProps) => {
    return medplum.executeBot(deactivateOrganization, {
      organizationId,
    });
  }, options);
};
