import * as React from 'react';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { Flex, Grid, Image, Text, Title } from '@mantine/core';
import { DownloadApp } from './DownloadApp';
import { getConfig } from '../../config';

const UserInvite = () => {
  const { googlePlayStoreUrl, appStoreUrl } = getConfig();

  React.useEffect(() => {
    if (isMobile && isIOS) {
      const deeplink = appStoreUrl;

      window.location.href = deeplink;
    }

    if (isMobile && isAndroid) {
      const deeplink = googlePlayStoreUrl;

      window.location.href = deeplink;
    }
  }, []);

  return (
    <Grid py={0}>
      <Grid.Col xs={12} sm={4} bg={'#FFFAF7'}>
        <Flex style={{ height: '100%' }}>
          <Image
            fit="contain"
            w={'100%'}
            h={'100%'}
            sx={{ flexShrink: 0 }}
            src="/img/download-mockup.png"
            alt="Soulfi app"
          />
        </Flex>
      </Grid.Col>
      <Grid.Col xs={12} sm={8} bg={'#FFFFFF'}>
        <Flex justify="center" direction="column" p={40} sx={{ height: '100%' }}>
          <Flex direction="column">
            <Title order={2}>Welcome to happiness destination</Title>
            {/* <Title order={2}>happier, healthier and more sustainable.</Title> */}
          </Flex>
          <Flex direction="column" my={80}>
            <Title order={4}>Download Soulfi</Title>
            <DownloadApp appStoreUrl={appStoreUrl} googlePlayStoreUrl={googlePlayStoreUrl} />
          </Flex>
          <Flex direction="column" mb={40}>
            <Title order={4}>Contact us</Title>
            <Text>If you have any questions or feedback, please contact us.</Text>
            <Link to="mailto:support@soulfi.com">support@soulfi.com</Link>
          </Flex>
        </Flex>
      </Grid.Col>
    </Grid>
  );
};

export default UserInvite;
