import React from 'react';
import { Grid, TextInput, Text, Input, Paper, Avatar, FileButton, Button } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useMedplum } from '@medplum/react';
import { Binary, Bundle, Group } from '@medplum/fhirtypes';
import { useTranslation } from 'react-i18next';
import { UpdatePatientFormData } from './User';
import { useGetGroups } from '../../queries/group/useGetGroups';
import { useGetGroupByPatientId } from '../../queries/group/useGetGroupByPatientId';
import { useChangeUsersGroup } from '../../queries/group/useChangeUsersGroup';
import { showNotification } from '@mantine/notifications';
import { useAuthMeStore } from '../../store/useAuthMeStore';

interface userProps {
  formData: UpdatePatientFormData;
  setFormData: (formData: any) => void;
  impactValue?: any;
}

export const EditUser: React.FC<userProps> = ({ formData, setFormData, impactValue }) => {
  const { t } = useTranslation();
  const medplum = useMedplum();
  const profile = medplum.getProfile();

  const [currentGroup, setCurrentGroup] = React.useState<Group | undefined>();

  const isCompanyAdmin = useAuthMeStore((s) => s.isCompanyAdmin);

  useGetGroupByPatientId(profile?.id as string, {
    onSuccess: (resp) => {
      setCurrentGroup(resp);
    },
  });

  const { data: groupData } = useGetGroups();

  const { mutateAsync: changePatientsGroup } = useChangeUsersGroup({
    onSuccess: async (resp: Bundle) => {
      setCurrentGroup(resp?.entry?.[1]?.resource as Group);
      showNotification({ color: 'green', message: t('users.user-group-changed-success') });
    },
  });

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];

    await medplum
      .createBinary(file, file.name, file.type)
      .then((binary: Binary) => {
        setFormData((prevData: UpdatePatientFormData) => ({
          ...prevData,
          photo: [
            {
              contentType: file.type,
              title: file.name,
              url: binary.url,
            },
          ],
        }));
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    setFormData((prevData: UpdatePatientFormData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevData: UpdatePatientFormData) => ({
      ...prevData,
      [name]: value,
      groupName: event.target.options[event.target.selectedIndex].text,
    }));
  };

  const handleChangePatientsGroup = async (event: any) => {
    const { value } = event.target;

    await changePatientsGroup({
      sourceGroup: [
        {
          sourceGroupId: currentGroup?.id as string,
          patientRefs: [
            {
              reference: `Patient/${profile?.id}`,
            },
          ],
        },
      ],
      targetGroupId: value,
    });
  };

  return (
    <>
      <form>
        <Grid sx={{ marginBottom: '15px' }}>
          <Grid.Col span={12} lg={4}>
            <Text style={{ fontSize: '14px', fontWeight: '500' }}>{t('common.name')}*</Text>
          </Grid.Col>
          <Grid.Col span={12} lg={4}>
            <TextInput
              placeholder={t('common.first-name')}
              name="firstName"
              onChange={handleInputChange}
              value={formData.firstName}
            />
          </Grid.Col>
          <Grid.Col span={12} lg={4}>
            <TextInput
              placeholder={t('common.last-name')}
              name="lastName"
              onChange={handleInputChange}
              value={formData.lastName}
            />
          </Grid.Col>
        </Grid>
        <Grid sx={{ marginBottom: '15px' }}>
          <Grid.Col span={12} lg={4}>
            <Text style={{ fontSize: '14px', fontWeight: '500' }}>{t('common.email')}*</Text>
          </Grid.Col>
          <Grid.Col span={12} lg={8}>
            <TextInput disabled placeholder={t('common.email')} name="email" value={formData.email} readOnly />
          </Grid.Col>
        </Grid>
        <Grid sx={{ marginBottom: '15px' }}>
          <Grid.Col span={12} lg={4}>
            <Text style={{ fontSize: '14px', fontWeight: '500' }}>{t('common.gender')}</Text>
          </Grid.Col>
          <Grid.Col span={12} lg={3}>
            <Input
              component="select"
              rightSection={<IconChevronDown size={14} stroke={1.5} />}
              onChange={handleSelectChange}
              value={formData.gender}
              name="gender"
            >
              <option>{t('common.select')}</option>
              <option value="male">{t('common.male')}</option>
              <option value="female">{t('common.female')}</option>
              <option value="other">{t('common.other')}</option>
            </Input>
          </Grid.Col>
        </Grid>
        {/* <Grid sx={{ marginBottom: '15px' }}>
          <Grid.Col span={12} lg={4}>
            <Text style={{ fontSize: '14px', fontWeight: '500' }}>{t('common.phone-number')}*</Text>
          </Grid.Col>
          <Grid.Col span={12} lg={4}>
            <Input
              component={IMaskInput}
              mask="+49 (000) 0000-000000"
              label={t('common.phone-number')}
              placeholder={t('common.phone-number')}
              name="phoneNo"
              value={formData.phoneNo}
              onChange={handleInputChange}
            />
          </Grid.Col>
        </Grid> */}
        <Grid sx={{ marginBottom: '15px' }}>
          <Grid.Col span={12} lg={4}>
            <Text style={{ fontSize: '14px', fontWeight: '500' }}>{t('common.image')}*</Text>
          </Grid.Col>
          <Grid.Col span={12} lg={8}>
            <Paper>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {formData.photo?.[0]?.url ? (
                  <div>
                    <img src={formData.photo?.[0]?.url} alt={formData.photo?.[0]?.title} style={{ width: '70%' }} />
                  </div>
                ) : (
                  <div>
                    <Avatar src="../../img/dashboard-assets/male-profile-icon.svg" size={120} radius="xl" />
                  </div>
                )}
                <FileButton onChange={handleFileChange} accept="image/png,image/jpeg">
                  {(props) => (
                    <Button variant="light" ml={12} size="sm" {...props}>
                      {t('common.upload-image')}
                    </Button>
                  )}
                </FileButton>
              </div>
            </Paper>
          </Grid.Col>
        </Grid>

        {isCompanyAdmin && (
          <Grid sx={{ marginBottom: '15px' }}>
            <Grid.Col span={12} lg={4}>
              <Text style={{ fontSize: '14px', fontWeight: '500' }}>{t('group')}</Text>
            </Grid.Col>
            <Grid.Col span={12} lg={8}>
              <Input
                component="select"
                rightSection={<IconChevronDown size={14} stroke={1.5} />}
                onChange={handleChangePatientsGroup}
                value={currentGroup?.id}
                defaultValue={currentGroup?.id}
                name="group"
              >
                <option>Select</option>
                {groupData?.map((option: Group) => <option value={option?.id}>{option?.name}</option>)}
              </Input>
            </Grid.Col>
          </Grid>
        )}
      </form>
    </>
  );
};
